
//function to filter past webinars by category
function webinarFilter(filter, results) {

    // Get Filter Value
    var filter_value = filter.val();

    // loop through results and show the correct
    // webinars and hide the other ones
    results.each(function () {

        // Current Webinar
        var result = jQuery(this);

        // if it has the filter class show it
        if (result.hasClass(filter_value)) {

            // Show
            result.show();

        } else {

            // Hide
            result.hide();
        }
    });
}


//Function controlling the Webinar Filter on Learning block
function setupWebinarFilter() {

    // Webinar Filter
    var filter = jQuery('.webinar-type');

    // Webinar List Singles
    var results = jQuery('.webinar-single');

    // Filter results on load
    webinarFilter(filter, results);

    // Adjust Webinars on Filter change.
    filter.on('change', function () {

        // Filter results on load
        webinarFilter(filter, results);
    });
}



