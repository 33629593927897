function render_carousel_page(){
	var carousel = jQuery('.carousel-inner');
	if( typeof carousel !== 'undefined' ){

		carousel.slick({
			arrows: true,
			dots: true,


		});
	}



}
function openHref(url) {
	if (window.app && window.app.openExternalBrowserWindow) {
		window.app.openExternalBrowserWindow(url);
	} else {
		window.open(url);
	}
}