// Function for handling the waypoints on
// the (optional) side block navigation
//
// Loop through each Side nav menu link and set a waypoint
// the waypoint will attach to the block that the
// menu item links to. When this waypoint is hit, it will add
// the active class to the correct menu item and remove it from
// the other menu items.

function setSideMenuActive(menu){
    menu.addClass('active').siblings().removeClass('active');
}


function sapSetupSideNavigation(){

    // Grab the side navigation
    var side_navigation = jQuery('.sap-side-block-navigation');

    // If side navigation is setup on the page
    if(typeof side_navigation !== 'undefined' ){
        // Loop through the side nav menu links
        side_navigation.find('.sap-side-nav-link').each(function(){
            // setup variable for current link
            var link = jQuery(this);

            // grab the block this menu links to
            var linked_block = jQuery(link.attr('href'));

            // Setup Waypoint
            var waypoint = new Waypoint({
                element: linked_block[0],
                handler: function(direction) {
                    if(direction === "up" ){
                        // If you are scrolling up, the previous link
                        // should be the active one.
                        setSideMenuActive( link.prev() );
                    }else{
                        setSideMenuActive( link );
                    }
                },
                offset: '50%'
            });
        });
    }
}