// Functions for creating a menu that sticks below the main menu on scroll

//Get the offset from the current menus
function sapGetMenuOffset(sub_menu){

    // sub_menu controls wether the sub menu (anchor or tab) gets
    // account for while calculating the height. Default is false.
    sub_menu = sub_menu || false;

    var offset = jQuery('.sap-page-banner').height();

    // If the WP admin bar is present, the menu should attach 32px lower
    if (jQuery('body').hasClass('admin-bar')) {
        offset += jQuery('#wpadminbar').outerHeight();
    }

    if(sub_menu){
        offset += jQuery('.sap-page-menu').height();
    }

    return offset;
}
function setMenuPosition(menu, offset){
    menu.css('top',offset);
}

// Setup a clone of the menu
// which will be visible fixed under the nav menu
function sapAttachMenuClone(original_menu) {
    var container = jQuery('.sap-main-content');

    var offset = sapGetMenuOffset();


    // Create the duplicate menu
    var sticky_menu = original_menu
        .clone()
        .addClass('stick');
        //.hide()
        //.css('top', offset);

    setMenuPosition(sticky_menu, offset);

    // Attach the menu to the page and return it
    return sticky_menu.appendTo(container);
}


// Check the position of the  menu,
// and see if the sticky nav should be visible
function sapCheckMenuPosition(original_menu, sticky_menu) {


    var offset = sapGetMenuOffset();
    setMenuPosition(sticky_menu, offset);

    jQuery(window).on('resize',function(){

        var offset = sapGetMenuOffset();
        setMenuPosition(sticky_menu, offset);
    });

    // on scroll check the menu position
    // if the regular menu is higher than the bottom of the header, we show the
    // sticky menu otherwise we hide it.
    jQuery(window).on('scroll', function () {
        if (jQuery(window).scrollTop() > original_menu.offset().top - parseInt(sticky_menu.css('top'))) {
            sticky_menu.addClass('active');
        } else {
            sticky_menu.removeClass('active');
        }
    });

}

// Page menu sticky functionality
function sapPageMenuSticky(menu) {

    // Setup sticky menu
    var original_menu = jQuery(menu);

    // if the page has an tab menu
    if (original_menu.length > 0) {

        // Setup Sticky Menu
        var sticky_menu = sapAttachMenuClone(original_menu);

        // Check if the sticky menu should be visible
        sapCheckMenuPosition(original_menu, sticky_menu);

    }

}

