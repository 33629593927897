
function setPageBannerHeights(){
    // full height on page
    var has_full_height = false;

    // Loop through banners
    jQuery('.sap-page-block-banner').each(function(){
        // set current banner
        var banner = jQuery(this);
        // Check if banner is full height
        if (!banner.hasClass('full-height') && !banner.hasClass('single-product') ){
            // Set height to auto to get new height
            banner.css('height','auto');
            // otherwise set height as current height (so it can center)
            banner.css('height',banner.outerHeight());
        }

    });
}
//Setup full height banner to offset Banner height
function sapSetBannerFullHeight(banner) {
    var onResize = function() {
        //window height
        var window_height = jQuery(window).height();

        // Menu Height
        var menu_height = jQuery(".sap-page-banner").height();

        // Set banner height to the window height minus the menu
        var banner = jQuery(".sap-page-block-banner.full-height")
            .css("height", window_height - menu_height );
    };

    // call it also when the page is ready after load or reload
    jQuery(function() {
        onResize();
    });
}

// Loop through each banner and set its height.
function setBannerHeights(){
    // full height on page
    var has_full_height = false;

    setPageBannerHeights();
    // set current banner
    var banner = jQuery(this);
    // Check if banner is full height
    if (banner.hasClass('full-height')){
        has_full_height = true;
    }
    // if there are full height banners, set them up
    if (has_full_height){
        // setup full height banners
        sapSetBannerFullHeight();
    }
    // attach the function to the window resize event
    jQuery(window).resize(setPageBannerHeights);
}


// Setup the slider on the animated banner
function setupBannerSlider(){
    jQuery('.animated-block-slide-wrap').slick({
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 2000,
    });
}