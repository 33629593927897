// Setup mombile menut opening & sub menu toggles
function sapMobileMenuSetup() {
    // Open / Close mobile menu on toggle click
    jQuery(".sap-mobile-menu-toggle")
        .click(function () {
            jQuery('.sap-mobile-menu')
                .stop(true, true)
                .slideToggle(200);
        });

    // Add Sub Menu toggle button to mobile menu
    jQuery(".sap-mobile-menu")
        .find('.sub-menu')
        .siblings('a')
        .after('<span class="sap-mobile-menu-slide"></span>');

    // On sub menu toggle click, toggle the sub-menu
    jQuery(".sap-mobile-menu")
        .on('click', '.sap-mobile-menu-slide', function () {
            jQuery(this)
                .toggleClass('open')
                .siblings('.sub-menu')
                .stop(true, true).slideToggle(200);
        });
}

// Adds padding to the body to offset the fixed height banner
// adjusts on screen size change
function sapMenuHeight() {
    var onResize = function () {
        // apply dynamic padding at the top of the body according to the fixed navbar height
        jQuery("body").css("padding-top", jQuery(".sap-page-banner").height() - 1);
        if (jQuery('body').hasClass('admin-bar')) {
            jQuery('.sap-page-banner').css('top', jQuery('#wpadminbar').outerHeight());
        }
    };

    // attach the function to the window resize event
    jQuery(window).resize(onResize);

    // call it also when the page is ready after load or reload
    jQuery(function () {
        onResize();
    });

}


// When a Popout Link is clicked, open in new window
function sapPopOutLinks(){
    jQuery(document).on('click','.popout-link',function(e){
        e.preventDefault();
        var _this = jQuery(this);
        window.open(_this.attr('href'), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');


    });
}


function sapSetupFrameContent(html, playerID){

    if(typeof playerID === 'undefined'){
        playerID = 'none';
    }

    var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
    var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
    var pattern3 = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;
    var replacement;

    if(pattern1.test(html)){
        replacement = '<iframe width="420" height="345" src="//player.vimeo.com/video/$1?autoplay=1&enablejsapi=1&playerapiid='+playerID+'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';

        html = html.replace(pattern1, replacement);
    }


    if(pattern2.test(html)){
        replacement = '<iframe width="420" height="345" src="http://www.youtube.com/embed/$1?autoplay=1&enablejsapi=1&playerapiid='+playerID+'" frameborder="0" allowfullscreen></iframe>';
        html = html.replace(pattern2, replacement);
    }


    if(pattern3.test(html)){
        replacement = '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
        html = html.replace(pattern3, replacement);
    }


    html = '<div class="entry-content-asset">' + html + '</div>';



    return html;


}

function sapLightboxContent(){
    //jQuery('.sap-lightbox-content').each(function(){
    jQuery(document).on('click','.sap-lightbox-content',function(e){
        e.preventDefault();

        var _this = jQuery(this);
        var id = 'sap-lightbox-content-' + Math.floor((Math.random() * 1000) + 1);
        var link = _this.attr('href');
        var iframe = sapSetupFrameContent(link);
        //var popupBox = jQuery('<div class="sap-lightbox-content-box" id="'+id+'">'+iframe+'</div>');

        //var body = jQuery('body').append(popupBox);

        //_this.attr('href','').attr('data-featherlight','#'+ id);


        jQuery.featherlight(iframe);
    });


}


