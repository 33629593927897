
// Setup of the carousel on the carousel block
function sapSetupBlockCarousel() {

    var carousel = jQuery('.carousel-navigation');
    var cBlock = jQuery('.sap-page-block-carousel').find('.carousel-block');

    // Setup Carousel
    cBlock.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        draggable: true,
        fade: true,
    });

    // Handle click of slide paging button
    carousel.on('click', '.carousel-link', function () {
        carousel.addClass('clicked');
        var button = jQuery(this);
        transitionSlide(button);
    });


    // Handle automatic rotation until manually clicked.
    (function cycleSlides() {
        if (!carousel.hasClass('clicked')) {
            var current = carousel.find('.active');
            var current_button;

            if (current.hasClass('last-slide')) {
                current_button = carousel.find('.first-slide');
            } else {
                current_button = current.next();
            }
            transitionSlide(current_button);
            setTimeout(cycleSlides, 2000);
        }
    })();

    function transitionSlide(button) {
        button.addClass('active').siblings().removeClass('active');
        cBlock.slick('slickGoTo',button.attr('data-slide'));
    }
}