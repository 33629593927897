function loadMoreAjax() {
    jQuery(".sap-ajax-loadmore").on('click', function (event) {
        var that = jQuery(this);

        event.preventDefault();
        var post_type = that.attr('data-post-type');
        var page = parseInt(that.attr('data-page'));
        var per_page = parseInt(that.attr('data-per-page'));
        var max_page = parseInt(that.attr('data-max-page'));
        var offset = parseInt(that.attr('data-offset'));
        var tax = that.attr('data-tax');
        var term = that.attr('data-term');

        if (page >= max_page) {
            that.addClass("hidden");
        }

        page++;
        console.log({
            action: 'sap_ajax_pagination',
            post_type: post_type,
            page: page,
            per_page: per_page,
            offset: offset,
            tax: tax,
            term: term}
        );
        jQuery.ajax({
            url: ajaxpagination.ajaxurl,
            type: 'post',
            data: {
                action: 'sap_ajax_pagination',
                post_type: post_type,
                page: page,
                per_page: per_page,
                offset: offset,
                tax: tax,
                term: term
            },
            success: function (html) {

                jQuery(html).appendTo(jQuery('.infographic-block'));
                that.attr('data-page', page);
                if (page >= max_page) {
                    that.addClass("hidden");
                }
            }
        });

    });

    jQuery('#infographic-filter').on('change', function (e) {

        var filter = jQuery(this).val();
        var url = jQuery(this).attr('data-url');
        if (filter !== "show-all") {
            url = url + "?filter=" + filter;
        }
        window.location.replace(url);

        //    jQuery(".sap-ajax-loadmore")
        //        .addClass('resort')
        //        .attr('data-tax','infographic_type')
        //        .attr('data-term', jQuery(this).val())
        //        .click();
    });
}