// Customer Stories Video Popover Functionality

function sapVideoBanner(){
    // Grab video block(s)
    var video_block = jQuery('.sap-page-block-video-banner');

    if(video_block.length > 0 ){

        // Add active class when play is clicked.
        video_block.on('click','.sap-video-play',function(){
            jQuery(this).parent().siblings('.video-box').addClass('active');
        });
        //Remove active class when close is clicked.
        video_block.on('click','.video-box-close',function(){
            jQuery(this).closest('.video-box').removeClass('active');
        });
    }
}//
