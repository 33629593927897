// Setups Slider if window is correct size, otherwise it destroys it
function runSlider() {
    // get all carousels on page
    var carousels = jQuery('.carousel-on-mobile');
    // Loop through each carousel
    carousels.each(function () {
        // set current carousel
        var carousel = jQuery(this);
        // If the screen is narrow enough for the carousel, set it.
        if (jQuery(window).width() < 1000) {


            // Set carousel
            carousel.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: (carousel.hasClass('dots')),
                arrows: (carousel.hasClass('arrows')),
                mobileFirst: true,
                touchMove: (!carousel.hasClass('arrows')),
                draggable: (!carousel.hasClass('arrows')),

                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,

                        }
                    },
                ]
            });
        } else {
            // If screen is wide, unset carousel
            if (carousel.hasClass('slick-initialized')) {
                carousel.slick('unslick');
            }
        }
    });
}
// Handles firing the slider on screen size change
function sapCalloutCarousel() {

    var carousel = jQuery('.carousel-on-mobile');
    if (carousel.length > 0) {
        runSlider();
        var r;
        jQuery(window).resize(function () {
            clearTimeout(r);
            r = setTimeout(runSlider, 500);
        });
    }

}



