// Set tab by the href
function setTabByHref(hash){
    // Find tab for that anchor and click it (to set it)
    jQuery('[data-tab-href=' + hash + ']').click();
    // Get offset for the top of tab container
    var top = jQuery('.sap-page-block-page-tabs').offset().top;
    // scroll to the top of the tab block
    sapAnimateTo(top, false, true);
}

// Check if a tab needs to be set on load
function checkTabsOnLoad() {
    var currentHash = window.location.hash;
    if (currentHash.length > 0) {
        // Remove the # from the anchor link
        currentHash = currentHash.replace("#", "");

        setTabByHref(currentHash);
    }
}

// main menu functionality
// if a main menu link is clicked for the current page tab items,
// disable normal functionality and click the tab instead.
jQuery('#menu-main-menu').on('click','.current-menu-item.tab-link a',function(event){
    // prevent default behaviour
    event.preventDefault();
    // get the link (so we can get the hash)
    var href = jQuery(this).attr('href');
    // set has value from the href
    var hash = href.substring(href.indexOf('#')+1);
    // set the tab based on the hash
    setTabByHref(hash);
});

// Set the current active tab/accordion
function setCurrentTab(tab, allow_closed) {

    // Sets if one tab must always be open (when accordions)
    if (typeof allow_closed === 'undefined') { allow_closed = false; }

    // Current Tab1
    var current_hash = jQuery(tab).attr('data-tab-href');
    var current = jQuery('[data-tab-href=' + current_hash + ']');

    window.location.hash = current_hash;
    //history.pushState({id: 'current_hash'}, '', window.location);


    // Add Active class to current tab
    // If allow_closed is true, toggle class instead
    if (allow_closed) {
        current.toggleClass('active');
    } else {
        current.addClass('active');
    }

    // Remove Active class from sibling
    current.siblings().removeClass('active');

    // Get class for tab-content of clicked tab
    var current_tab = jQuery(current.attr('data-tab'));

    // Set selected tab-content as active
    // If allow_closed is true, toggle class instead
    if (allow_closed) {
        current_tab.toggleClass('active').find('.wow').addClass('animated');
    } else {
        current_tab.addClass('active').find('.wow').addClass('animated');
    }

    // Remove active class from siblings
    current_tab.siblings().removeClass('active');

    // Set main menu link as active
    // Look for a link to this tab in the main menu on the current page.
    var main_menu_link = jQuery('#menu-main-menu').find('.current-menu-item.tab-' + current_hash);

    // if it finds a link, set it to active and unset its siblings.
    if (main_menu_link.length > 0) {

        // set as active, unset siblings
        main_menu_link.addClass('active').siblings().removeClass('active');
    }


}

// Mobile Menu toggle functionality
function sapMobileTabToggle(){
    // Mobile Menu Toggle
    jQuery('.mobile-menu-toggle').on('click',function(){
        // Find menu again
        jQuery('.mobile-menu-toggle')
            .toggleClass('open')
            .next('.sap-page-menu-container').slideToggle();
    });

    // Close Menu on click
    jQuery('.sap-page-menu-container').on('click','.tab',function(){
        // This toggle
        var tab = jQuery(this);
        // menu toggle
        var menu_toggle = jQuery('.mobile-menu-toggle');
        // set menu toggle text to current tab.
        menu_toggle.html(tab.html());
        // if mobile menu has open class, click it (to close it)
        if(menu_toggle.hasClass('open')){
            menu_toggle.removeClass('open')
                .next('.sap-page-menu-container').slideToggle();
        }
    });
}

// Setup height of tab triggers
function setTabTriggerHeights(tabs){

    tabs.find('.sap-page-menu-container-tabs').each(function(){
        var tab = jQuery(this);
        tab.css('height', tab.height() );
    });
}

// Setup the Page Tab Functionality
function sapPageTabs() {

    // set height of tab container (so all tabs can be height 100%)
    setTabTriggerHeights(jQuery('.tab-navigation'));
    
    // Setup Tab menu sticky functionality
    sapPageMenuSticky('.sap-page-block-page-tab-navigation');

    // get the tabs
    var tabs = jQuery('.tab-navigation');



    // on a tab click, set that tab as active
    tabs.on('click', '.tab', function () {
        setCurrentTab(this);
    });

    // on a accordion click, set that accordion as active
    jQuery('.sap-page-block-page-tabs').on('click', '.sap-page-accordion-navigation', function () {
        setCurrentTab(this, true);
    });

    // Check if tab is set on load
    checkTabsOnLoad();


    // Setup Mobile Drop Menu Toggle
    sapMobileTabToggle();





    // When hashchanges (hitting back) change tabs
    jQuery( window ).on('hashchange',function() {
        checkTabsOnLoad();
    });
}


