/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {


                // by default it takes ua string from current browser's window.navigator.userAgent


                // JavaScript to be fired on all pages

                // uncomment this line to bring back animations
                // new WOW().init();

                // Select 2 for Select Boxes
                jQuery('select').select2({minimumResultsForSearch: -1});

                // Setup Mobile Menu JS
                sapMobileMenuSetup(); // navigation.js
                sapMenuHeight(); // navigation.js
                sapPopOutLinks(); // navigation.js
                sapLightboxContent(); // navigation.js

                setBannerHeights(); //banner.js

                // Attach smooth scrolling
                sapSmoothAnchorScroll(); // page-anchors.js

                // Open Chat popup for Contact Methods
                //sapContactMethods(); //contact-methods.js

                // Function for the optional side navigation block
                sapSetupSideNavigation(); //side-navigation.js

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

            }
        },
        // Page Block Specific JS //

        // Setup Anchor Nav Sticky functionality
        'page_block_page_anchors': {
            init: function () {
                sapPageAnchors(); // page-anchors.js
            }
        },
        // Setup Page Tabs Functionality
        'page_block_page_tabs': {
            init: function () {
                sapPageTabs(); // page-tabs.js

            }
        },
        // Setup Carousel Block Functionality
        'page_block_callouts': {
            init: function () {
                sapCalloutCarousel(); // callout-carousel.js
            }
        },
        // Setup Frame Carousel Block
        'page_block_framed_carousel': {
            init: function () {
                sapCarouselFramed(); // carousel-framed.js
            }
        },
        // Setup Frame Carousel Block
        'page_block_framed_video_carousel': {
            init: function () {
                sapCarouselFramedVideoTabs(); // carousel-framed.js
            }
        },
        // Setup Carousel Block
        'page_block_carousel': {
            init: function () {
                sapSetupBlockCarousel(); // carousel-paging.js
            }
        },
        // Setup Video Banner Functionality
        'page_block_video_banner': {
            init: function () {
                sapVideoBanner(); // video-banner.js
            }
        },
        // Setup Video Banner Functionality
        'page_block_learning_block': {
            init: function () {
                setupWebinarFilter(); // webinars.js
            }
        },
        // Setup Video Banner Functionality
        'page_block_animated_screen': {
            init: function () {
                setupBannerSlider(); //banner.js
            }
        },
        // Page carousel functionality
        'page_template_template_carousel': {
            init: function () {
                render_carousel_page(); //banner.js
            }
        },
        // Setup Load More Infographics Functionality
        'page_block_infographics_index': {
            init: function () {
                loadMoreAjax(); //loadmore.js
            }
        },
        'single_global_goal': {

            init: function () {
                //var parser = new UAParser();
                //var browser = parser.getBrowser().name;
                //if (browser === "IE" || browser === "Edge") {
                //    if ($('#data-regular-container').hasClass('has-animation')) {
                //        $('#lumira-ie-warning').removeClass('hidden');
                //        $('#data-ie-container').removeClass("hidden");
                //        $('#data-regular-container').addClass("hidden");
                //    }
                //}
                //$('#lumira-ie-warning-close').on('click', function () {
                //    $('#lumira-ie-warning').slideUp();
                //});
            }
        },


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
