
// Customer Stories Video Popover Functionality

function sapCustomerStories(){
    // Grab story block(s)
    //var story_block = jQuery('.sap-story-video');
    //
    //if(story_block.length > 0 ){
    //
    //    // Add active class when play is clicked.
    //    story_block.on('click','.sap-story-play',function(){
    //        jQuery(this).parent().siblings('.video-box').addClass('active');
    //    });
    //    //Remove active class when close is clicked.
    //    story_block.on('click','.video-box-close',function(){
    //        jQuery(this).closest('.video-box').removeClass('active');
    //    });
    //}
}