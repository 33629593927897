// animate page to set top offset
function sapAnimateTo(location, consider_header )  {
    // consider_header will set wether we offset the scroll
    // to account for the header (will also account for active submenus);
    if (typeof consider_header === 'undefined') { consider_header = true; }
    var anchor_menu;
    if (consider_header){
        // get menu height for offset
        var header_bottom = jQuery('.sap-page-banner').height();
        // get anchor menu for offsetting the anchor menu (not needed for tab menu)
        anchor_menu = jQuery('.sap-page-block-page-anchors');

        // add in offset for sticky header menu
        location -= header_bottom;

        // if anchor menu is currently stuck to top add offset
        if (typeof(anchor_menu) !== 'undefined') {
            location -= anchor_menu.height();
        }

    }else{

        // get anchor menu for offsetting the anchor menu (not needed for tab menu)
        anchor_menu = jQuery('.sap-page-block-page-tab-navigation');


        // if anchor menu is currently stuck to top add offset
        if (typeof(anchor_menu) !== 'undefined') {
            location -= anchor_menu.height();
        }

    }

    // if wp admin bar is visible, add offset for its height
    if (jQuery('body').hasClass('admin-bar')) {
        location -= jQuery('#wpadminbar').height();
    }

    // Animate the scrollTop to the desired locaiton
    jQuery('html, body').stop().animate({
        'scrollTop': location
    }, 900, 'swing', function () {
        //window.location.hash = hash;
    });
}

// Adds a smooth scroll to page anchors
function sapSmoothAnchorScroll() {

    // Add click event to links that start with a hash
    jQuery('.sap-main-content').on('click', 'a[href^="#"]', function (e) {
        // Disable normal anchor function
        e.preventDefault();
        // make sure the link exsists
        if (jQuery(this)) {
            // get the hashe
            var hash = jQuery(this).attr('href');
            var target = jQuery(hash);
            var top;

            // If no target offset, default to top of page (blank #)
            // Otherwise set top as the target offset
            if (typeof(target.offset() ) === 'undefined') {
                top = 0;
            }else{
                top = target.offset().top;
            }
            sapAnimateTo(top);
        }
    });
}

// Control the position of the cutout arrow
function shiftAnchorArrow(anchor){

    var position = anchor.offset().left + ((anchor.outerWidth() - 13 )/ 2 ) ;

    // Arrow Cutout
    var arrow_slide = jQuery('.bottom-arrow-slider');
    // Animate slide position
    arrow_slide.css('left', position);
}

//Position arrow on initial load
function sapPositionArrowOnLoad(){
    // Arrow Cutout
    var arrow_slide = jQuery('.bottom-arrow-slider');

    // if it has left 0, it needs to be positioned
    if(arrow_slide.offset().left < 1){
        // Shift anchor to the active anchor position
        shiftAnchorArrow(jQuery('.anchor.active'));
    }
}


// Mobile Menu toggle functionality
function sapMobileAnchorToggle(){
    // Mobile Menu Toggle
    jQuery('.mobile-menu-toggle').on('click',function(){
        // Find menu again
        jQuery('.mobile-menu-toggle')
            .toggleClass('open')
            .next('.sap-page-menu-container').slideToggle();

    });

    // Close Menu on click
    jQuery('.sap-page-menu-container').on('click','.anchor',function(){
        // if mobile menu has open class, click it (to close it)
        jQuery(this).parents('.sap-page-menu')
            .find('.mobile-menu-toggle.open').click();
    });
}
// Set the current anchor as active
function setSetActiveMenu(anchor_hash) {

    // Find the anchor
    var anchor = jQuery('.anchor[href=' + anchor_hash + ']');

    // set anchor as active, and unset siblings
    anchor.addClass('active').siblings().removeClass('active');

    // Set the arrow positon
    shiftAnchorArrow(anchor);
}

// Setup individual Waypoint anchors
function sapAnchorWaypoint(anchor) {

    // Get height of menus as offset for waypoint
    var offset = sapGetMenuOffset(true);

    // Need to set up 2 waypoints, scrolling up
    // fires 1px later than scrolling down.
    new Waypoint({
        element: jQuery(anchor)[0],
        offset: offset,
        handler: function (direction) {
            setSetActiveMenu(anchor);
        }
    });
    new Waypoint({
        element: jQuery(anchor)[0],
        offset: offset -1,
        handler: function (direction) {
            setSetActiveMenu(anchor);
        }
    });
}

// Switch Active state when Banner is at top
function sapAnchorActiveState() {

    // Anchor Menu
    var anchor_menu = jQuery('.sap-page-block-page-anchors');

    // Loop through each anchor link to set waypoint
    anchor_menu.find('.anchor').each(function () {
        sapAnchorWaypoint(jQuery(this).attr('href'));
    });

}


// Setup sticky functionality for Page Tabs
function sapPageAnchors() {

    //Position arrow on initial load
    sapPositionArrowOnLoad();

    // Setup Tab menu sticky functionality
    sapPageMenuSticky('.sap-page-block-page-anchors');

    // Switch Active state when Banner is at top
    sapAnchorActiveState();

    // Setup Mobile Drop Menu Toggle
    sapMobileAnchorToggle();

}

