// Setup Framed Carousel
function sapCarouselFramed(){
    var carouselBlock = jQuery('.sap-page-block-framed-carousel');



    if( typeof carouselBlock !== 'undefined' ){

        var carousel = carouselBlock.find('.carousel-block');
        var carouselText = carouselBlock.find('.carousel-text');

        carousel.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            asNavFor: ".carousel-text",
            touchMove: false,
            draggable: false,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        dots: true,
                        touchMove: true,
                        draggable: true,
                        arrows: false,
                    }
                },
            ]
        });

        carouselText.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: ".carousel-block",
            dots: false,
            arrows: false,
            touchMove: false,
            draggable: false
        });
    }

}



// Setup Framed Carousel
function sapCarouselFramedVideoTabs(){
    var carouselBlock = jQuery('.sap-page-block-framed-carousel');

    if( typeof carouselBlock !== 'undefined' ){

        var carousel = carouselBlock.find('.carousel-block');
        var carouselText = carouselBlock.find('.carousel-text');

        carousel.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            asNavFor: ".carousel-text",
            touchMove: false,
            draggable: false,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        dots: true,
                        touchMove: true,
                        draggable: true,
                        arrows: false,
                    }
                },
            ]
        });

        carouselText.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: ".carousel-block",
            dots: false,
            arrows: false,
            touchMove: false,
            draggable: false
        });

        carousel.on('beforeChange', function(event, slick, currentSlide, nextSlide){
            carouselBlock.find('.tab-count-' + nextSlide).addClass('active').siblings().removeClass('active');
        });

    }

    carouselBlock.on('click','.play-icon',function(){

        var _this = jQuery(this);
        var link = _this.attr('data-video');
        var iframe = sapSetupFrameContent(link);
        //var popupBox = jQuery('<div class="sap-lightbox-content-box" id="'+id+'">'+iframe+'</div>');

        //var body = jQuery('body').append(popupBox);

        //_this.attr('href','').attr('data-featherlight','#'+ id);


        jQuery.featherlight(iframe);
    });



    var tabs = carouselBlock.find('.carousel-tabs');
    tabs.on('click','.carousel-tab',function(){
       jQuery(this).addClass('active').siblings().removeClass('active');
        carousel.slick('slickGoTo',jQuery(this).attr('data-tab'));
    });
}



